import { Component, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { BaseComponent } from '@app/shared/base/components/base-component';
import { __ } from '@app/shared/functions/object.functions';
import { SearchBy } from '@app/shared/models/enums/SearchBys';
import { SearchBysHelper } from '@app/shared/models/helpers/SearchBys.helper';

@Component({
    selector: 'sound-search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls: ['./search-bar.component.scss'],
    standalone: false
})
export class SearchBarComponent extends BaseComponent {

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC INSTANCE VARIABLES
  // -----------------------------------------------------------------------------------------------------

  form: UntypedFormGroup;

  // -----------------------------------------------------------------------------------------------------
  // @ OUTPUT VARIABLES
  // -----------------------------------------------------------------------------------------------------

  // -----------------------------------------------------------------------------------------------------
  // @ INPUT VARIABLES
  // -----------------------------------------------------------------------------------------------------

  private _freeTextSearchText: string = null;

  @Input()
  get freeTextSearchText(): string {
    return this._freeTextSearchText;
  }

  set freeTextSearchText(value: string) {
    this._freeTextSearchText = value;
    
    if (!this.IsNullOrUndefined(value)) {
      this.setSearchForm();
    }
  }

  private _standardTextSearchText: string = null;

  @Input()
  get standardTextSearchText(): string {
    return this._standardTextSearchText;
  }

  set standardTextSearchText(value: string) {
    this._standardTextSearchText = value;

    if (!this.IsNullOrUndefined(value)) {
      this.setSearchForm();
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private fb: UntypedFormBuilder,
    private authenticationService: AuthenticationService
  ) {
    super();

    this.setSearchForm();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ PRIVATE METHODS
  // -----------------------------------------------------------------------------------------------------

  setSearchForm(): void {
    this.form = this.fb.group({
      values: [SearchBysHelper.SearchBys
        .filter(q => {
          // Do not show mp3 value when the user is not authenticated
          if (q.value === SearchBy.MP3 && this.authenticationService.isAuthenticated() === false) {
            return false;
          }

          // Do not show individual license banners, when the user is authenticated and the organisation setting is turned off
          if (q.value === SearchBy.Soundtaxi && this.authenticationService.isAuthenticated() === true && this.authenticationService?.user?.hideIndividualLicenseBanners === true) {
            return false;
          }

          // For every other option show it
          return true;
        })],
      selected: [this.getSelected()],
      searchText: this.getSearchText()
    });
  }


  getSelected() : SearchBysHelper {
    if (this.IsNullOrUndefinedOrEmpty(this.freeTextSearchText) === false) {
      return SearchBysHelper.GetSearchBysByValueOrNull(SearchBy.FreeTextSearch);
    }

    if (this.IsNullOrUndefinedOrEmpty(this.standardTextSearchText) === false)
    {
      return SearchBysHelper.GetSearchBysByValueOrNull(SearchBy.Search);
    }

    return SearchBysHelper.GetSearchBysByValueOrNull(SearchBy.Spotify);
  }

  getSearchText() : string {
    if (this.IsNullOrUndefinedOrEmpty(this.freeTextSearchText) === false) {
      return this.freeTextSearchText;
    }

    if (this.IsNullOrUndefinedOrEmpty(this.standardTextSearchText) === false)
    {
      return this.standardTextSearchText;
    }

    return null;
  }
}
